(function() {
  'use strict';

  function ImporterService($q, AsyncTasks, ImporterTasks, Security) {
    // This service contains all the functions that fetch and send data, the aim is that it can be
    // replace later by external API calls for example
    var service = {};

    service.getAvailableEventTypes = function() {
      return ImporterTasks.getAvailableEventTypes();
    };

    service.getExistingImportsByOrigin = function(origin, state) {
      if (!origin) {
        return [];
      }
      return ImporterTasks.importerSearchOrigin(origin, state);
    };

    function hasPermissions(permissions) {
      var perms = _.map(permissions, function(perm) {
        return Security.hasPermission(perm)
          .then(function() {
            return perm;
          })
          .catch(function() {
            return false;
          });
      });

      return $q.all(perms)
        .then(function(results) {
          return _.filter(results, function(result) {
            return result !== false;
          });
        });
    }

    service.getAllUserPermissions = function() {
      // todo: cache this!
      var permissionsToCheck = {
        'importer.users': [
          'users.create',
          'users.edit',
          'users.edit.own',
          'roles.assign',
          'roles.assign.own',
          'credential.manage',
          'credential.manage.own'
        ],
        'importer.events': [
          'events.create',
          'events.create.own',
          'events.edit',
          'events.edit.own',
          'events.editCompleted',
          'events.editCompleted.own',
          'goals.manage'
        ]
      };
      return hasPermissions(_.keys(permissionsToCheck))
        .then(function(userBasePermissions) {
          // should get all main perms e.g ['importer.users', 'importer.events']
          // do not check the subPerms if the user do not have the main one: e.g importer.x
          var subPermsProms = _.map(userBasePermissions, function(userBasePermission) {
            var subPerms = permissionsToCheck[userBasePermission];
            return hasPermissions(subPerms);
          });
          return $q.all(subPermsProms);
        })
        .then(function(results) {
          var mainPermBySubPerm = {};
          _.forEach(permissionsToCheck, function(subPerms, mainPerm) {
            _.forEach(subPerms, function(subPerm) {
              mainPermBySubPerm[subPerm] = mainPerm;
            });
          });

          // should get all perms for each main one: e.g [[users.X], [events.X]]
          var userPerms = {};
          _.forEach(results, function(subPerms) {
            _.forEach(subPerms, function(subPerm) {
              var mainPerm = mainPermBySubPerm[subPerm];
              if (_.isUndefined(userPerms[mainPerm])) {
                userPerms[mainPerm] = [];
              }

              userPerms[mainPerm].push(subPerm);
            });
          });

          return userPerms;
        })

        // this part should be removed before we release for all users.
        .then(function(userPerms) {
          if (_.isEmpty(userPerms)) {
            return {};
          }
          return userPerms;
        })
        .catch(function() {
          return {};
        });
    };

    service.downloadCSVTemplate = function(docType, extra) {
      var data = { docType: docType };
      if (!_.isUndefined(extra)) {
        data.extra = extra;
      }

      return AsyncTasks.importerDownloadTemplate(docType, JSON.stringify(extra));
    };

    service.getKzFields = function(docType, extra, action) {
      var data = { docType: docType };
      if (!_.isUndefined(extra)) {
        data.extra = extra;
      }

      return AsyncTasks.importerKzFields(data)
        .then(function(kzFields) {
          return _.filter(kzFields, function(kzField) {
            return !(action === 'forceCreate' && kzField._id === 'idempotencyKey');
          });
        });
    };

    service.importFile = function(
      name,
      docType,
      extra,
      bulkFileName,
      bulkFileColumns,
      dataFile,
      attachedFiles,
      mapping,
      entryUniqueness,
      options,
      stateOptions,
      origin,
      importId,
      dryrun
    ) {
      if (docType === 'user') {
        extra = {};
      }

      var postData = {
        name: name,
        docType: docType,
        extra: extra,
        bulkFileName: bulkFileName,
        bulkFileColumns: bulkFileColumns,
        file: dataFile,
        attachedFiles: attachedFiles,
        mapping: mapping,
        entryUniqueness: entryUniqueness,
        options: options,
        stateOptions: stateOptions,
        origin: origin,
        dryrun: dryrun
      };

      if (importId) {
        return AsyncTasks.restart(importId, postData);
      }

      return AsyncTasks.start('import_file', postData);
    };

    service.reImportFileWithoutChanges = function(importId, dryrun) {
      if (!importId) {
        return $q.reject("importId can't be empty when re-importing ");
      }

      return AsyncTasks.restart(importId, { dryrun: dryrun });
    };

    service.previewItem = function(importId, ItemPosition) {
      return AsyncTasks.importerPreviewItem(importId, ItemPosition);
    };

    return service;
  }

  ImporterService.$inject = [
    '$q',
    'AsyncTasksService',
    'ImporterTasksService',
    'SecurityService'
  ];

  angular.module('component.importer')
    .factory('ImporterService', ImporterService);
})();
